import React, {
  useState,
  useReducer,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react"
import { useHotkeys } from "react-hotkeys-hook"
import produce from "immer"
import { set, isEqual } from "lodash/fp"
import { Box, Flex, Stack } from "@chakra-ui/core"
import { Grid } from "@cwio/components"
import Confetti from "react-confetti"

import {
  usePuzzleByIdQuery,
  useUpsertSolveMutation,
  useMyPuzzleSolveQuery,
} from "../../generated/graphql"
import {
  preprocessPuzzle,
  isBlack,
  nextSquareAtOffset,
  nextFillSquareAtOffset,
} from "./preprocess"
import { useParams } from "react-router-dom"
import { useAuth0 } from "app/src/auth0"

const ClueList = (props) => {
  const { currentClue } = props
  const ref = useRef<HTMLDivElement>()

  useLayoutEffect(() => {
    if (ref.current && currentClue) {
      const element: HTMLElement | null = document.querySelector(
        `*[data-clue=${props.direction}${currentClue}]`
      )

      if (element?.offsetTop) {
        ref.current?.scrollTo({
          top: element?.offsetTop - ref.current?.offsetTop,
          behavior: "smooth",
        })
      }
    }
  }, [currentClue])

  const clueColor = props.active ? "rgb(170, 203, 255)" : "rgb(234, 234, 234)"

  return (
    <Box>
      <Box mb={2}>
        <b>{props.direction}</b>
      </Box>

      <Box maxW={250} maxH={600} overflowY="scroll" ref={ref}>
        {props.clues.map((clue) => (
          <Flex
            backgroundColor={clue.n === currentClue ? clueColor : null}
            data-clue={`${props.direction}${clue.n}`}
            key={clue.n}
            px={2}
            py={1}
            onClick={() => props.onClick(clue.n)}
          >
            <b style={{ minWidth: 30 }}>{clue.n}</b>
            <span>{clue.text}</span>
          </Flex>
        ))}
      </Box>
    </Box>
  )
}

const KEY_TO_OFFSET = {
  ArrowUp: [-1, 0],
  ArrowDown: [1, 0],
  ArrowLeft: [0, -1],
  ArrowRight: [0, 1],
}

interface PuzzleState {
  fill: string[][]
  cursor: [number, number]
  direction: number // 0, 1 <-> down, across.
  puzzle: any
}

type Handlers = Record<string, (state: PuzzleState, action: any) => void>

const handlers: Handlers = {
  move: (state, action) => {
    const [dr, dc] = KEY_TO_OFFSET[action.key]
    const [r, c] = state.cursor
    state.cursor = nextSquareAtOffset(
      state.puzzle,
      state.fill,
      [r, c],
      [dr, dc]
    )
  },
  moveTo: (state, action) => {
    if (isBlack(state.puzzle, action.pos)) return

    if (isEqual(state.cursor, action.pos)) {
      state.direction = 1 - state.direction
    }
    state.cursor = action.pos
  },
  fill: (state, action) => {
    const [r, c] = state.cursor
    state.fill[r][c] = action.key.toUpperCase()

    state.cursor = nextFillSquareAtOffset(
      state.puzzle,
      state.fill,
      [r, c],
      state.direction === 0 ? [1, 0] : [0, 1]
    )
  },
  remove: (state, action) => {
    const [r, c] = state.cursor
    state.fill[r][c] = ""

    // TODO: Should be:
    // 1) If square is blank, delete previous square and focus.
    // 2) If square is filled, delete square and keep cursor pos.
    state.cursor = nextSquareAtOffset(
      state.puzzle,
      state.fill,
      [r, c],
      state.direction === 0 ? [-1, 0] : [0, -1]
    )
  },
  changeDirection: (state, action) => {
    state.direction = 1 - state.direction
  },
  selectClue: (state, action) => {
    state.direction = action.direction
    state.cursor =
      state.puzzle.numberToPos[
        state.puzzle.clueHash[`${action.clue}${"DA"[action.direction]}`].n
      ]
  },
  moveToNextClue: (state, action) => {
    const [r, c] = state.cursor
    const currentClueNumber =
      state.puzzle.posToClue[state.direction][`${r},${c}`]

    const lookup = (action.forward
      ? state.puzzle.nextClue
      : state.puzzle.previousClue) as any

    state.cursor =
      state.puzzle.numberToPos[lookup[state.direction][currentClueNumber]]
  },
}

const reducer = (state, action) => {
  if (!handlers[action.type]) return state
  return produce(state, (draftState) =>
    handlers[action.type](draftState, action)
  )
}

const formatTime = (value) =>
  `${Math.floor(value / 60)}:${(value % 60).toString().padStart(2, "0")}`

const Timer = (props) => {
  const [diff, setDiff] = useState(0)

  useEffect(() => {
    if (!props.disabled) {
      const timer = setInterval(() => {
        setDiff(
          Math.floor((new Date().getTime() - props.start.getTime()) / 1000)
        )
      }, 500)

      return () => {
        clearInterval(timer)
      }
    }
  }, [props.disabled])

  return <Box>{formatTime(diff + props.offset)}</Box>
}

const PuzzlePlayer = (props) => {
  const [start, setStart] = useState(new Date())
  const { puzzle } = props
  const [state, dispatch] = useReducer(reducer, {
    fill: props.defaultFill || puzzle.shape.map((row) => row.map((c) => "")),
    cursor: puzzle.numberToPos[1],
    direction: 1,
    puzzle: puzzle,
  })

  const [solved, setSolved] = useState(props.defaultSolved)
  const [celebrating, setCelebrating] = useState(false)

  const { fill, cursor, direction } = state
  const [r, c] = cursor
  const currentClueNumber = puzzle.posToClue[direction][`${r},${c}`]
  const currentClueHash = `${currentClueNumber}${"DA"[direction]}`

  useHotkeys("*", (event, handler) => {
    console.log(event, handler)

    if (KEY_TO_OFFSET[event.key]) {
      dispatch({ type: "move", key: event.key })
    } else if (event.code === "Space") {
      dispatch({ type: "changeDirection" })
    } else if (event.code === "Backspace") {
      dispatch({ type: "remove" })
    } else if (event.code === "Tab") {
      dispatch({ type: "moveToNextClue", forward: !event.shiftKey })
      event.preventDefault()
    } else if (event.key.length === 1 && !event.metaKey && !event.ctrlKey) {
      // TODO: Filter modifiers better..
      dispatch({ type: "fill", key: event.key })
    }
  })

  const onClick = useCallback(([r, c], e) => {
    dispatch({ type: "moveTo", pos: [r, c] })
    e.preventDefault()
  }, [])

  const onClickClue = (direction) => (clue) => {
    dispatch({ type: "selectClue", clue, direction })
  }

  const [mutate] = useUpsertSolveMutation()

  useEffect(() => {
    const unloader = () => {
      if (!solved) submit()
    }
    const submit = async () => {
      const { data } = await mutate({
        variables: {
          solves: [
            {
              user_id: null,
              puzzle_id: props.puzzleId,
              fill,
              time_in_seconds:
                props.defaultTimeElapsed +
                Math.floor((new Date().getTime() - start.getTime()) / 1000),
            },
          ],
        },
      })

      const isSolved = !!data?.insert_solve?.returning?.[0]?.solved
      setSolved(isSolved)
      if (isSolved) {
        setCelebrating(true)
        setTimeout(() => setCelebrating(false), 3000)
      }
    }

    if (!solved) submit()
    window.addEventListener("beforeunload", unloader)

    return () => {
      window.removeEventListener("beforeunload", unloader)
    }
  }, [fill, solved])

  // console.log("solve status", props.defaultSolved)

  return (
    <Box p={2} px={4}>
      <Box mb={3}>
        <Flex>
          <Box flex="1 1 auto">
            <Box style={{ fontWeight: 500, fontSize: "1.2em" }}>
              {puzzle.title}
            </Box>
            <Box>{puzzle.author}</Box>
          </Box>

          {props.solveTime ? (
            <Box>{formatTime(props.solveTime)}</Box>
          ) : (
            <Timer
              disabled={solved}
              start={start}
              offset={props.defaultTimeElapsed}
            />
          )}
        </Flex>
      </Box>
      <Flex>
        {celebrating ? <Confetti width={540} height={700} /> : null}

        <Box mr={3}>
          <Box mb={2} maxW={540} height="2.5rem">
            {currentClueHash} {puzzle.clueHash[currentClueHash].text}
          </Box>
          <Grid width={540} {...state} solved={solved} onClick={onClick} />
        </Box>
        <Stack direction="row" spacing={2}>
          <ClueList
            direction="Across"
            clues={puzzle.clues.filter((c) => c.direction === "A")}
            currentClue={puzzle.posToClue[1][`${r},${c}`]}
            active={direction === 1}
            onClick={onClickClue(1)}
          />
          <ClueList
            direction="Down"
            clues={puzzle.clues.filter((c) => c.direction === "D")}
            currentClue={puzzle.posToClue[0][`${r},${c}`]}
            active={direction === 0}
            onClick={onClickClue(0)}
          />
        </Stack>
      </Flex>
    </Box>
  )
}

const PuzzleLoader = (props) => {
  const { userId } = useAuth0()
  const { id } = useParams()
  const { data, loading } = usePuzzleByIdQuery({ variables: { id } })
  const { data: solve, loading: loadingSolve } = useMyPuzzleSolveQuery({
    variables: { puzzleId: id, user: userId },
    fetchPolicy: "network-only",
  })

  if (!data?.puzzle_by_pk || !solve?.solve) return null
  const p = preprocessPuzzle(data.puzzle_by_pk.data)

  return (
    <PuzzlePlayer
      defaultFill={solve?.solve?.[0]?.fill}
      defaultTimeElapsed={solve?.solve?.[0]?.time_in_seconds || 0}
      defaultSolved={solve?.solve?.[0]?.solved}
      solveTime={solve?.solve?.[0]?.solve_time}
      puzzle={p}
      puzzleId={data.puzzle_by_pk.id}
    />
  )
}

export default PuzzleLoader
