import React from "react"
import { Link } from "react-router-dom"
import { useSolvesQuery } from "app/src/generated/graphql"
import { Box } from "@chakra-ui/core"

import { useAuth0 } from "../../auth0"

const formatTime = (value) =>
  `${Math.floor(value / 60)}:${(value % 60).toString().padStart(2, "0")}`

const SolveHistory = () => {
  const { userId } = useAuth0()

  const { data, loading } = useSolvesQuery({
    variables: { where: { user: { _eq: userId }, solved: { _eq: true } } },
    fetchPolicy: "cache-and-network",
  })

  console.log(data)

  return (
    <Box p={2} px={4}>
      {data?.solve
        ? data.solve.map((solve) => (
            <Box key={solve.id} py={1}>
              <Box>
                <Link to={`/puzzle/${solve.puzzle.id}`}>
                  {solve.puzzle.title}
                </Link>

                {solve.solve_time ? formatTime(solve.solve_time) : null}
              </Box>
            </Box>
          ))
        : null}
    </Box>
  )
}

export default SolveHistory
