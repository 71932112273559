import React from "react"

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import { ThemeProvider, Flex, Button } from "@chakra-ui/core"
import { ApolloProvider } from "react-apollo"

import Home from "./components/Home/Home"
import PuzzlePlayer from "./components/Puzzle/PuzzlePlayer"
import AuthorizedApolloProvider from "./apollo"

import { Auth0Provider, useAuth0 } from "./auth0"
import NavBar from "./components/Nav"
import SolveHistory from "./components/Stats/History"

const onRedirectCallback = (appState) => {
  // history.push(
  //   appState && appState.targetUrl
  //     ? appState.targetUrl
  //     : window.location.pathname
  // )
}

const AuthenticatedRoute = () => {
  const { loading } = useAuth0()

  if (loading) return null

  return (
    <AuthorizedApolloProvider>
      <ThemeProvider>
        <Router>
          <div>
            <NavBar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/stats" component={SolveHistory} />
              <Route exact path="/puzzle/:id" component={PuzzlePlayer} />
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </AuthorizedApolloProvider>
  )
}

const App = () => (
  <Auth0Provider
    domain="bgz.auth0.com"
    client_id="Z53X703DOkaapJovzMoXIeG6HbLwLDxz"
    redirect_uri={window.location.origin}
    cacheLocation="localstorage"
    audience="hasura"
  >
    <AuthenticatedRoute />
  </Auth0Provider>
)

export default App
