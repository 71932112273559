import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/link-context"
import React, { useMemo } from "react"

import { useAuth0 } from "./auth0"

const AuthorizedApolloProvider = ({ children }) => {
  const { getTokenSilently, auth0 } = useAuth0()

  const apolloClient = useMemo(() => {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    })

    const authLink = setContext(async () => {
      // console.log("here...", await getTokenSilently())
      const token = await getTokenSilently()
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    })

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    })
  }, [!!auth0])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
