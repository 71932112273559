import React from "react"
import { useAuth0 } from "../auth0"
import { Link } from "react-router-dom"
import { Flex, Button } from "@chakra-ui/core"

const NavBar = () => {
  const {
    loading,
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0()

  return (
    <Flex alignItems="center" p={2} px={4} borderBottom="1px solid #eee">
      <Link to="/">crosswords.io</Link>
      <Flex alignItems="center" justifyContent="flex-end" flex="1 1 auto">
        <Link to="/stats">Stats</Link>
        {user ? user.email : null}
        {!loading && !isAuthenticated && (
          <Button size="xs" onClick={() => loginWithRedirect({})}>
            Log in
          </Button>
        )}

        {!loading && isAuthenticated && (
          <Button
            size="xs"
            onClick={() => logout({ returnTo: "http://localhost:3000/" })}
          >
            Log out
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default NavBar
