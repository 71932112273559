import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  jsonb: any;
  timestamptz: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "puzzle" */
  delete_puzzle?: Maybe<Puzzle_Mutation_Response>;
  /** delete single row from the table: "puzzle" */
  delete_puzzle_by_pk?: Maybe<Puzzle>;
  /** delete data from the table: "puzzle_tag" */
  delete_puzzle_tag?: Maybe<Puzzle_Tag_Mutation_Response>;
  /** delete single row from the table: "puzzle_tag" */
  delete_puzzle_tag_by_pk?: Maybe<Puzzle_Tag>;
  /** delete data from the table: "solve" */
  delete_solve?: Maybe<Solve_Mutation_Response>;
  /** delete single row from the table: "solve" */
  delete_solve_by_pk?: Maybe<Solve>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "puzzle" */
  insert_puzzle?: Maybe<Puzzle_Mutation_Response>;
  /** insert a single row into the table: "puzzle" */
  insert_puzzle_one?: Maybe<Puzzle>;
  /** insert data into the table: "puzzle_tag" */
  insert_puzzle_tag?: Maybe<Puzzle_Tag_Mutation_Response>;
  /** insert a single row into the table: "puzzle_tag" */
  insert_puzzle_tag_one?: Maybe<Puzzle_Tag>;
  /** insert data into the table: "solve" */
  insert_solve?: Maybe<Solve_Mutation_Response>;
  /** insert a single row into the table: "solve" */
  insert_solve_one?: Maybe<Solve>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "puzzle" */
  update_puzzle?: Maybe<Puzzle_Mutation_Response>;
  /** update single row of the table: "puzzle" */
  update_puzzle_by_pk?: Maybe<Puzzle>;
  /** update data of the table: "puzzle_tag" */
  update_puzzle_tag?: Maybe<Puzzle_Tag_Mutation_Response>;
  /** update single row of the table: "puzzle_tag" */
  update_puzzle_tag_by_pk?: Maybe<Puzzle_Tag>;
  /** update data of the table: "solve" */
  update_solve?: Maybe<Solve_Mutation_Response>;
  /** update single row of the table: "solve" */
  update_solve_by_pk?: Maybe<Solve>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootDelete_PuzzleArgs = {
  where: Puzzle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Puzzle_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Puzzle_TagArgs = {
  where: Puzzle_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Puzzle_Tag_By_PkArgs = {
  puzzle_id: Scalars['Int'];
  tag_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SolveArgs = {
  where: Solve_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Solve_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_PuzzleArgs = {
  objects: Array<Puzzle_Insert_Input>;
  on_conflict?: Maybe<Puzzle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Puzzle_OneArgs = {
  object: Puzzle_Insert_Input;
  on_conflict?: Maybe<Puzzle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Puzzle_TagArgs = {
  objects: Array<Puzzle_Tag_Insert_Input>;
  on_conflict?: Maybe<Puzzle_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Puzzle_Tag_OneArgs = {
  object: Puzzle_Tag_Insert_Input;
  on_conflict?: Maybe<Puzzle_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SolveArgs = {
  objects: Array<Solve_Insert_Input>;
  on_conflict?: Maybe<Solve_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Solve_OneArgs = {
  object: Solve_Insert_Input;
  on_conflict?: Maybe<Solve_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: Maybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: Maybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_PuzzleArgs = {
  _append?: Maybe<Puzzle_Append_Input>;
  _delete_at_path?: Maybe<Puzzle_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Puzzle_Delete_Elem_Input>;
  _delete_key?: Maybe<Puzzle_Delete_Key_Input>;
  _inc?: Maybe<Puzzle_Inc_Input>;
  _prepend?: Maybe<Puzzle_Prepend_Input>;
  _set?: Maybe<Puzzle_Set_Input>;
  where: Puzzle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Puzzle_By_PkArgs = {
  _append?: Maybe<Puzzle_Append_Input>;
  _delete_at_path?: Maybe<Puzzle_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Puzzle_Delete_Elem_Input>;
  _delete_key?: Maybe<Puzzle_Delete_Key_Input>;
  _inc?: Maybe<Puzzle_Inc_Input>;
  _prepend?: Maybe<Puzzle_Prepend_Input>;
  _set?: Maybe<Puzzle_Set_Input>;
  pk_columns: Puzzle_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Puzzle_TagArgs = {
  _inc?: Maybe<Puzzle_Tag_Inc_Input>;
  _set?: Maybe<Puzzle_Tag_Set_Input>;
  where: Puzzle_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Puzzle_Tag_By_PkArgs = {
  _inc?: Maybe<Puzzle_Tag_Inc_Input>;
  _set?: Maybe<Puzzle_Tag_Set_Input>;
  pk_columns: Puzzle_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SolveArgs = {
  _append?: Maybe<Solve_Append_Input>;
  _delete_at_path?: Maybe<Solve_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Solve_Delete_Elem_Input>;
  _delete_key?: Maybe<Solve_Delete_Key_Input>;
  _inc?: Maybe<Solve_Inc_Input>;
  _prepend?: Maybe<Solve_Prepend_Input>;
  _set?: Maybe<Solve_Set_Input>;
  where: Solve_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Solve_By_PkArgs = {
  _append?: Maybe<Solve_Append_Input>;
  _delete_at_path?: Maybe<Solve_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Solve_Delete_Elem_Input>;
  _delete_key?: Maybe<Solve_Delete_Key_Input>;
  _inc?: Maybe<Solve_Inc_Input>;
  _prepend?: Maybe<Solve_Prepend_Input>;
  _set?: Maybe<Solve_Set_Input>;
  pk_columns: Solve_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _inc?: Maybe<Tags_Inc_Input>;
  _set?: Maybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _inc?: Maybe<Tags_Inc_Input>;
  _set?: Maybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "puzzle" */
export type Puzzle = {
  __typename?: 'puzzle';
  author?: Maybe<Scalars['String']>;
  data: Scalars['jsonb'];
  date?: Maybe<Scalars['date']>;
  hash: Scalars['String'];
  id: Scalars['Int'];
  /** An array relationship */
  puzzle_tags: Array<Puzzle_Tag>;
  /** An aggregated array relationship */
  puzzle_tags_aggregate: Puzzle_Tag_Aggregate;
  solution?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  solves: Array<Solve>;
  /** An aggregated array relationship */
  solves_aggregate: Solve_Aggregate;
  title: Scalars['String'];
};


/** columns and relationships of "puzzle" */
export type PuzzleDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "puzzle" */
export type PuzzlePuzzle_TagsArgs = {
  distinct_on?: Maybe<Array<Puzzle_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Tag_Order_By>>;
  where?: Maybe<Puzzle_Tag_Bool_Exp>;
};


/** columns and relationships of "puzzle" */
export type PuzzlePuzzle_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Puzzle_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Tag_Order_By>>;
  where?: Maybe<Puzzle_Tag_Bool_Exp>;
};


/** columns and relationships of "puzzle" */
export type PuzzleSolutionArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "puzzle" */
export type PuzzleSolvesArgs = {
  distinct_on?: Maybe<Array<Solve_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Solve_Order_By>>;
  where?: Maybe<Solve_Bool_Exp>;
};


/** columns and relationships of "puzzle" */
export type PuzzleSolves_AggregateArgs = {
  distinct_on?: Maybe<Array<Solve_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Solve_Order_By>>;
  where?: Maybe<Solve_Bool_Exp>;
};

/** aggregated selection of "puzzle" */
export type Puzzle_Aggregate = {
  __typename?: 'puzzle_aggregate';
  aggregate?: Maybe<Puzzle_Aggregate_Fields>;
  nodes: Array<Puzzle>;
};

/** aggregate fields of "puzzle" */
export type Puzzle_Aggregate_Fields = {
  __typename?: 'puzzle_aggregate_fields';
  avg?: Maybe<Puzzle_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Puzzle_Max_Fields>;
  min?: Maybe<Puzzle_Min_Fields>;
  stddev?: Maybe<Puzzle_Stddev_Fields>;
  stddev_pop?: Maybe<Puzzle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Puzzle_Stddev_Samp_Fields>;
  sum?: Maybe<Puzzle_Sum_Fields>;
  var_pop?: Maybe<Puzzle_Var_Pop_Fields>;
  var_samp?: Maybe<Puzzle_Var_Samp_Fields>;
  variance?: Maybe<Puzzle_Variance_Fields>;
};


/** aggregate fields of "puzzle" */
export type Puzzle_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Puzzle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "puzzle" */
export type Puzzle_Aggregate_Order_By = {
  avg?: Maybe<Puzzle_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Puzzle_Max_Order_By>;
  min?: Maybe<Puzzle_Min_Order_By>;
  stddev?: Maybe<Puzzle_Stddev_Order_By>;
  stddev_pop?: Maybe<Puzzle_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Puzzle_Stddev_Samp_Order_By>;
  sum?: Maybe<Puzzle_Sum_Order_By>;
  var_pop?: Maybe<Puzzle_Var_Pop_Order_By>;
  var_samp?: Maybe<Puzzle_Var_Samp_Order_By>;
  variance?: Maybe<Puzzle_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Puzzle_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
  solution?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "puzzle" */
export type Puzzle_Arr_Rel_Insert_Input = {
  data: Array<Puzzle_Insert_Input>;
  on_conflict?: Maybe<Puzzle_On_Conflict>;
};

/** aggregate avg on columns */
export type Puzzle_Avg_Fields = {
  __typename?: 'puzzle_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "puzzle" */
export type Puzzle_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "puzzle". All fields are combined with a logical 'AND'. */
export type Puzzle_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Puzzle_Bool_Exp>>>;
  _not?: Maybe<Puzzle_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Puzzle_Bool_Exp>>>;
  author?: Maybe<String_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  hash?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  puzzle_tags?: Maybe<Puzzle_Tag_Bool_Exp>;
  solution?: Maybe<Jsonb_Comparison_Exp>;
  solves?: Maybe<Solve_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "puzzle" */
export enum Puzzle_Constraint {
  /** unique or primary key constraint */
  PuzzlesHashKey = 'puzzles_hash_key',
  /** unique or primary key constraint */
  PuzzlesPkey = 'puzzles_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Puzzle_Delete_At_Path_Input = {
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
  solution?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Puzzle_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
  solution?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Puzzle_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
  solution?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "puzzle" */
export type Puzzle_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "puzzle" */
export type Puzzle_Insert_Input = {
  author?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['jsonb']>;
  date?: Maybe<Scalars['date']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  puzzle_tags?: Maybe<Puzzle_Tag_Arr_Rel_Insert_Input>;
  solution?: Maybe<Scalars['jsonb']>;
  solves?: Maybe<Solve_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Puzzle_Max_Fields = {
  __typename?: 'puzzle_max_fields';
  author?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "puzzle" */
export type Puzzle_Max_Order_By = {
  author?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Puzzle_Min_Fields = {
  __typename?: 'puzzle_min_fields';
  author?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "puzzle" */
export type Puzzle_Min_Order_By = {
  author?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "puzzle" */
export type Puzzle_Mutation_Response = {
  __typename?: 'puzzle_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Puzzle>;
};

/** input type for inserting object relation for remote table "puzzle" */
export type Puzzle_Obj_Rel_Insert_Input = {
  data: Puzzle_Insert_Input;
  on_conflict?: Maybe<Puzzle_On_Conflict>;
};

/** on conflict condition type for table "puzzle" */
export type Puzzle_On_Conflict = {
  constraint: Puzzle_Constraint;
  update_columns: Array<Puzzle_Update_Column>;
  where?: Maybe<Puzzle_Bool_Exp>;
};

/** ordering options when selecting data from "puzzle" */
export type Puzzle_Order_By = {
  author?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  puzzle_tags_aggregate?: Maybe<Puzzle_Tag_Aggregate_Order_By>;
  solution?: Maybe<Order_By>;
  solves_aggregate?: Maybe<Solve_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "puzzle" */
export type Puzzle_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Puzzle_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
  solution?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "puzzle" */
export enum Puzzle_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  Data = 'data',
  /** column name */
  Date = 'date',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Solution = 'solution',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "puzzle" */
export type Puzzle_Set_Input = {
  author?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['jsonb']>;
  date?: Maybe<Scalars['date']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  solution?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Puzzle_Stddev_Fields = {
  __typename?: 'puzzle_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "puzzle" */
export type Puzzle_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Puzzle_Stddev_Pop_Fields = {
  __typename?: 'puzzle_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "puzzle" */
export type Puzzle_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Puzzle_Stddev_Samp_Fields = {
  __typename?: 'puzzle_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "puzzle" */
export type Puzzle_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Puzzle_Sum_Fields = {
  __typename?: 'puzzle_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "puzzle" */
export type Puzzle_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "puzzle_tag" */
export type Puzzle_Tag = {
  __typename?: 'puzzle_tag';
  /** An object relationship */
  puzzle: Puzzle;
  puzzle_id: Scalars['Int'];
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['Int'];
};

/** aggregated selection of "puzzle_tag" */
export type Puzzle_Tag_Aggregate = {
  __typename?: 'puzzle_tag_aggregate';
  aggregate?: Maybe<Puzzle_Tag_Aggregate_Fields>;
  nodes: Array<Puzzle_Tag>;
};

/** aggregate fields of "puzzle_tag" */
export type Puzzle_Tag_Aggregate_Fields = {
  __typename?: 'puzzle_tag_aggregate_fields';
  avg?: Maybe<Puzzle_Tag_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Puzzle_Tag_Max_Fields>;
  min?: Maybe<Puzzle_Tag_Min_Fields>;
  stddev?: Maybe<Puzzle_Tag_Stddev_Fields>;
  stddev_pop?: Maybe<Puzzle_Tag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Puzzle_Tag_Stddev_Samp_Fields>;
  sum?: Maybe<Puzzle_Tag_Sum_Fields>;
  var_pop?: Maybe<Puzzle_Tag_Var_Pop_Fields>;
  var_samp?: Maybe<Puzzle_Tag_Var_Samp_Fields>;
  variance?: Maybe<Puzzle_Tag_Variance_Fields>;
};


/** aggregate fields of "puzzle_tag" */
export type Puzzle_Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Puzzle_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "puzzle_tag" */
export type Puzzle_Tag_Aggregate_Order_By = {
  avg?: Maybe<Puzzle_Tag_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Puzzle_Tag_Max_Order_By>;
  min?: Maybe<Puzzle_Tag_Min_Order_By>;
  stddev?: Maybe<Puzzle_Tag_Stddev_Order_By>;
  stddev_pop?: Maybe<Puzzle_Tag_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Puzzle_Tag_Stddev_Samp_Order_By>;
  sum?: Maybe<Puzzle_Tag_Sum_Order_By>;
  var_pop?: Maybe<Puzzle_Tag_Var_Pop_Order_By>;
  var_samp?: Maybe<Puzzle_Tag_Var_Samp_Order_By>;
  variance?: Maybe<Puzzle_Tag_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "puzzle_tag" */
export type Puzzle_Tag_Arr_Rel_Insert_Input = {
  data: Array<Puzzle_Tag_Insert_Input>;
  on_conflict?: Maybe<Puzzle_Tag_On_Conflict>;
};

/** aggregate avg on columns */
export type Puzzle_Tag_Avg_Fields = {
  __typename?: 'puzzle_tag_avg_fields';
  puzzle_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Avg_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "puzzle_tag". All fields are combined with a logical 'AND'. */
export type Puzzle_Tag_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Puzzle_Tag_Bool_Exp>>>;
  _not?: Maybe<Puzzle_Tag_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Puzzle_Tag_Bool_Exp>>>;
  puzzle?: Maybe<Puzzle_Bool_Exp>;
  puzzle_id?: Maybe<Int_Comparison_Exp>;
  tag?: Maybe<Tags_Bool_Exp>;
  tag_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "puzzle_tag" */
export enum Puzzle_Tag_Constraint {
  /** unique or primary key constraint */
  PuzzleTagPkey = 'puzzle_tag_pkey'
}

/** input type for incrementing integer column in table "puzzle_tag" */
export type Puzzle_Tag_Inc_Input = {
  puzzle_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "puzzle_tag" */
export type Puzzle_Tag_Insert_Input = {
  puzzle?: Maybe<Puzzle_Obj_Rel_Insert_Input>;
  puzzle_id?: Maybe<Scalars['Int']>;
  tag?: Maybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Puzzle_Tag_Max_Fields = {
  __typename?: 'puzzle_tag_max_fields';
  puzzle_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Max_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Puzzle_Tag_Min_Fields = {
  __typename?: 'puzzle_tag_min_fields';
  puzzle_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Min_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "puzzle_tag" */
export type Puzzle_Tag_Mutation_Response = {
  __typename?: 'puzzle_tag_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Puzzle_Tag>;
};

/** input type for inserting object relation for remote table "puzzle_tag" */
export type Puzzle_Tag_Obj_Rel_Insert_Input = {
  data: Puzzle_Tag_Insert_Input;
  on_conflict?: Maybe<Puzzle_Tag_On_Conflict>;
};

/** on conflict condition type for table "puzzle_tag" */
export type Puzzle_Tag_On_Conflict = {
  constraint: Puzzle_Tag_Constraint;
  update_columns: Array<Puzzle_Tag_Update_Column>;
  where?: Maybe<Puzzle_Tag_Bool_Exp>;
};

/** ordering options when selecting data from "puzzle_tag" */
export type Puzzle_Tag_Order_By = {
  puzzle?: Maybe<Puzzle_Order_By>;
  puzzle_id?: Maybe<Order_By>;
  tag?: Maybe<Tags_Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "puzzle_tag" */
export type Puzzle_Tag_Pk_Columns_Input = {
  puzzle_id: Scalars['Int'];
  tag_id: Scalars['Int'];
};

/** select columns of table "puzzle_tag" */
export enum Puzzle_Tag_Select_Column {
  /** column name */
  PuzzleId = 'puzzle_id',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "puzzle_tag" */
export type Puzzle_Tag_Set_Input = {
  puzzle_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Puzzle_Tag_Stddev_Fields = {
  __typename?: 'puzzle_tag_stddev_fields';
  puzzle_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Stddev_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Puzzle_Tag_Stddev_Pop_Fields = {
  __typename?: 'puzzle_tag_stddev_pop_fields';
  puzzle_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Stddev_Pop_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Puzzle_Tag_Stddev_Samp_Fields = {
  __typename?: 'puzzle_tag_stddev_samp_fields';
  puzzle_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Stddev_Samp_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Puzzle_Tag_Sum_Fields = {
  __typename?: 'puzzle_tag_sum_fields';
  puzzle_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Sum_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** update columns of table "puzzle_tag" */
export enum Puzzle_Tag_Update_Column {
  /** column name */
  PuzzleId = 'puzzle_id',
  /** column name */
  TagId = 'tag_id'
}

/** aggregate var_pop on columns */
export type Puzzle_Tag_Var_Pop_Fields = {
  __typename?: 'puzzle_tag_var_pop_fields';
  puzzle_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Var_Pop_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Puzzle_Tag_Var_Samp_Fields = {
  __typename?: 'puzzle_tag_var_samp_fields';
  puzzle_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Var_Samp_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Puzzle_Tag_Variance_Fields = {
  __typename?: 'puzzle_tag_variance_fields';
  puzzle_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "puzzle_tag" */
export type Puzzle_Tag_Variance_Order_By = {
  puzzle_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** update columns of table "puzzle" */
export enum Puzzle_Update_Column {
  /** column name */
  Author = 'author',
  /** column name */
  Data = 'data',
  /** column name */
  Date = 'date',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Solution = 'solution',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Puzzle_Var_Pop_Fields = {
  __typename?: 'puzzle_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "puzzle" */
export type Puzzle_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Puzzle_Var_Samp_Fields = {
  __typename?: 'puzzle_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "puzzle" */
export type Puzzle_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Puzzle_Variance_Fields = {
  __typename?: 'puzzle_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "puzzle" */
export type Puzzle_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "puzzle" */
  puzzle: Array<Puzzle>;
  /** fetch aggregated fields from the table: "puzzle" */
  puzzle_aggregate: Puzzle_Aggregate;
  /** fetch data from the table: "puzzle" using primary key columns */
  puzzle_by_pk?: Maybe<Puzzle>;
  /** fetch data from the table: "puzzle_tag" */
  puzzle_tag: Array<Puzzle_Tag>;
  /** fetch aggregated fields from the table: "puzzle_tag" */
  puzzle_tag_aggregate: Puzzle_Tag_Aggregate;
  /** fetch data from the table: "puzzle_tag" using primary key columns */
  puzzle_tag_by_pk?: Maybe<Puzzle_Tag>;
  /** fetch data from the table: "solve" */
  solve: Array<Solve>;
  /** fetch aggregated fields from the table: "solve" */
  solve_aggregate: Solve_Aggregate;
  /** fetch data from the table: "solve" using primary key columns */
  solve_by_pk?: Maybe<Solve>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** query root */
export type Query_RootPuzzleArgs = {
  distinct_on?: Maybe<Array<Puzzle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Order_By>>;
  where?: Maybe<Puzzle_Bool_Exp>;
};


/** query root */
export type Query_RootPuzzle_AggregateArgs = {
  distinct_on?: Maybe<Array<Puzzle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Order_By>>;
  where?: Maybe<Puzzle_Bool_Exp>;
};


/** query root */
export type Query_RootPuzzle_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPuzzle_TagArgs = {
  distinct_on?: Maybe<Array<Puzzle_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Tag_Order_By>>;
  where?: Maybe<Puzzle_Tag_Bool_Exp>;
};


/** query root */
export type Query_RootPuzzle_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Puzzle_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Tag_Order_By>>;
  where?: Maybe<Puzzle_Tag_Bool_Exp>;
};


/** query root */
export type Query_RootPuzzle_Tag_By_PkArgs = {
  puzzle_id: Scalars['Int'];
  tag_id: Scalars['Int'];
};


/** query root */
export type Query_RootSolveArgs = {
  distinct_on?: Maybe<Array<Solve_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Solve_Order_By>>;
  where?: Maybe<Solve_Bool_Exp>;
};


/** query root */
export type Query_RootSolve_AggregateArgs = {
  distinct_on?: Maybe<Array<Solve_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Solve_Order_By>>;
  where?: Maybe<Solve_Bool_Exp>;
};


/** query root */
export type Query_RootSolve_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootTagsArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


/** query root */
export type Query_RootTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


/** query root */
export type Query_RootTags_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "solve" */
export type Solve = {
  __typename?: 'solve';
  created_at: Scalars['timestamptz'];
  fill?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  /** An object relationship */
  puzzle: Puzzle;
  puzzle_id: Scalars['Int'];
  solution?: Maybe<Scalars['String']>;
  solve_time?: Maybe<Scalars['Int']>;
  solved: Scalars['Boolean'];
  time_in_seconds: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "solve" */
export type SolveFillArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "solve" */
export type Solve_Aggregate = {
  __typename?: 'solve_aggregate';
  aggregate?: Maybe<Solve_Aggregate_Fields>;
  nodes: Array<Solve>;
};

/** aggregate fields of "solve" */
export type Solve_Aggregate_Fields = {
  __typename?: 'solve_aggregate_fields';
  avg?: Maybe<Solve_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Solve_Max_Fields>;
  min?: Maybe<Solve_Min_Fields>;
  stddev?: Maybe<Solve_Stddev_Fields>;
  stddev_pop?: Maybe<Solve_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Solve_Stddev_Samp_Fields>;
  sum?: Maybe<Solve_Sum_Fields>;
  var_pop?: Maybe<Solve_Var_Pop_Fields>;
  var_samp?: Maybe<Solve_Var_Samp_Fields>;
  variance?: Maybe<Solve_Variance_Fields>;
};


/** aggregate fields of "solve" */
export type Solve_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Solve_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "solve" */
export type Solve_Aggregate_Order_By = {
  avg?: Maybe<Solve_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Solve_Max_Order_By>;
  min?: Maybe<Solve_Min_Order_By>;
  stddev?: Maybe<Solve_Stddev_Order_By>;
  stddev_pop?: Maybe<Solve_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Solve_Stddev_Samp_Order_By>;
  sum?: Maybe<Solve_Sum_Order_By>;
  var_pop?: Maybe<Solve_Var_Pop_Order_By>;
  var_samp?: Maybe<Solve_Var_Samp_Order_By>;
  variance?: Maybe<Solve_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Solve_Append_Input = {
  fill?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "solve" */
export type Solve_Arr_Rel_Insert_Input = {
  data: Array<Solve_Insert_Input>;
  on_conflict?: Maybe<Solve_On_Conflict>;
};

/** aggregate avg on columns */
export type Solve_Avg_Fields = {
  __typename?: 'solve_avg_fields';
  id?: Maybe<Scalars['Float']>;
  puzzle_id?: Maybe<Scalars['Float']>;
  solve_time?: Maybe<Scalars['Float']>;
  time_in_seconds?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "solve" */
export type Solve_Avg_Order_By = {
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "solve". All fields are combined with a logical 'AND'. */
export type Solve_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Solve_Bool_Exp>>>;
  _not?: Maybe<Solve_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Solve_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  fill?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  puzzle?: Maybe<Puzzle_Bool_Exp>;
  puzzle_id?: Maybe<Int_Comparison_Exp>;
  solution?: Maybe<String_Comparison_Exp>;
  solve_time?: Maybe<Int_Comparison_Exp>;
  solved?: Maybe<Boolean_Comparison_Exp>;
  time_in_seconds?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "solve" */
export enum Solve_Constraint {
  /** unique or primary key constraint */
  SolvePkey = 'solve_pkey',
  /** unique or primary key constraint */
  SolvePuzzleIdUserKey = 'solve_puzzle_id_user_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Solve_Delete_At_Path_Input = {
  fill?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Solve_Delete_Elem_Input = {
  fill?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Solve_Delete_Key_Input = {
  fill?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "solve" */
export type Solve_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  puzzle_id?: Maybe<Scalars['Int']>;
  solve_time?: Maybe<Scalars['Int']>;
  time_in_seconds?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "solve" */
export type Solve_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  fill?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['Int']>;
  puzzle?: Maybe<Puzzle_Obj_Rel_Insert_Input>;
  puzzle_id?: Maybe<Scalars['Int']>;
  solution?: Maybe<Scalars['String']>;
  solve_time?: Maybe<Scalars['Int']>;
  solved?: Maybe<Scalars['Boolean']>;
  time_in_seconds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Solve_Max_Fields = {
  __typename?: 'solve_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  puzzle_id?: Maybe<Scalars['Int']>;
  solution?: Maybe<Scalars['String']>;
  solve_time?: Maybe<Scalars['Int']>;
  time_in_seconds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "solve" */
export type Solve_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solution?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Solve_Min_Fields = {
  __typename?: 'solve_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  puzzle_id?: Maybe<Scalars['Int']>;
  solution?: Maybe<Scalars['String']>;
  solve_time?: Maybe<Scalars['Int']>;
  time_in_seconds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "solve" */
export type Solve_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solution?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "solve" */
export type Solve_Mutation_Response = {
  __typename?: 'solve_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Solve>;
};

/** input type for inserting object relation for remote table "solve" */
export type Solve_Obj_Rel_Insert_Input = {
  data: Solve_Insert_Input;
  on_conflict?: Maybe<Solve_On_Conflict>;
};

/** on conflict condition type for table "solve" */
export type Solve_On_Conflict = {
  constraint: Solve_Constraint;
  update_columns: Array<Solve_Update_Column>;
  where?: Maybe<Solve_Bool_Exp>;
};

/** ordering options when selecting data from "solve" */
export type Solve_Order_By = {
  created_at?: Maybe<Order_By>;
  fill?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  puzzle?: Maybe<Puzzle_Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solution?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  solved?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "solve" */
export type Solve_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Solve_Prepend_Input = {
  fill?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "solve" */
export enum Solve_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fill = 'fill',
  /** column name */
  Id = 'id',
  /** column name */
  PuzzleId = 'puzzle_id',
  /** column name */
  Solution = 'solution',
  /** column name */
  SolveTime = 'solve_time',
  /** column name */
  Solved = 'solved',
  /** column name */
  TimeInSeconds = 'time_in_seconds',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "solve" */
export type Solve_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  fill?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['Int']>;
  puzzle_id?: Maybe<Scalars['Int']>;
  solution?: Maybe<Scalars['String']>;
  solve_time?: Maybe<Scalars['Int']>;
  solved?: Maybe<Scalars['Boolean']>;
  time_in_seconds?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Solve_Stddev_Fields = {
  __typename?: 'solve_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  puzzle_id?: Maybe<Scalars['Float']>;
  solve_time?: Maybe<Scalars['Float']>;
  time_in_seconds?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "solve" */
export type Solve_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Solve_Stddev_Pop_Fields = {
  __typename?: 'solve_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  puzzle_id?: Maybe<Scalars['Float']>;
  solve_time?: Maybe<Scalars['Float']>;
  time_in_seconds?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "solve" */
export type Solve_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Solve_Stddev_Samp_Fields = {
  __typename?: 'solve_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  puzzle_id?: Maybe<Scalars['Float']>;
  solve_time?: Maybe<Scalars['Float']>;
  time_in_seconds?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "solve" */
export type Solve_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Solve_Sum_Fields = {
  __typename?: 'solve_sum_fields';
  id?: Maybe<Scalars['Int']>;
  puzzle_id?: Maybe<Scalars['Int']>;
  solve_time?: Maybe<Scalars['Int']>;
  time_in_seconds?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "solve" */
export type Solve_Sum_Order_By = {
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "solve" */
export enum Solve_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fill = 'fill',
  /** column name */
  Id = 'id',
  /** column name */
  PuzzleId = 'puzzle_id',
  /** column name */
  Solution = 'solution',
  /** column name */
  SolveTime = 'solve_time',
  /** column name */
  Solved = 'solved',
  /** column name */
  TimeInSeconds = 'time_in_seconds',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Solve_Var_Pop_Fields = {
  __typename?: 'solve_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  puzzle_id?: Maybe<Scalars['Float']>;
  solve_time?: Maybe<Scalars['Float']>;
  time_in_seconds?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "solve" */
export type Solve_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Solve_Var_Samp_Fields = {
  __typename?: 'solve_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  puzzle_id?: Maybe<Scalars['Float']>;
  solve_time?: Maybe<Scalars['Float']>;
  time_in_seconds?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "solve" */
export type Solve_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Solve_Variance_Fields = {
  __typename?: 'solve_variance_fields';
  id?: Maybe<Scalars['Float']>;
  puzzle_id?: Maybe<Scalars['Float']>;
  solve_time?: Maybe<Scalars['Float']>;
  time_in_seconds?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "solve" */
export type Solve_Variance_Order_By = {
  id?: Maybe<Order_By>;
  puzzle_id?: Maybe<Order_By>;
  solve_time?: Maybe<Order_By>;
  time_in_seconds?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "puzzle" */
  puzzle: Array<Puzzle>;
  /** fetch aggregated fields from the table: "puzzle" */
  puzzle_aggregate: Puzzle_Aggregate;
  /** fetch data from the table: "puzzle" using primary key columns */
  puzzle_by_pk?: Maybe<Puzzle>;
  /** fetch data from the table: "puzzle_tag" */
  puzzle_tag: Array<Puzzle_Tag>;
  /** fetch aggregated fields from the table: "puzzle_tag" */
  puzzle_tag_aggregate: Puzzle_Tag_Aggregate;
  /** fetch data from the table: "puzzle_tag" using primary key columns */
  puzzle_tag_by_pk?: Maybe<Puzzle_Tag>;
  /** fetch data from the table: "solve" */
  solve: Array<Solve>;
  /** fetch aggregated fields from the table: "solve" */
  solve_aggregate: Solve_Aggregate;
  /** fetch data from the table: "solve" using primary key columns */
  solve_by_pk?: Maybe<Solve>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** subscription root */
export type Subscription_RootPuzzleArgs = {
  distinct_on?: Maybe<Array<Puzzle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Order_By>>;
  where?: Maybe<Puzzle_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPuzzle_AggregateArgs = {
  distinct_on?: Maybe<Array<Puzzle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Order_By>>;
  where?: Maybe<Puzzle_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPuzzle_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPuzzle_TagArgs = {
  distinct_on?: Maybe<Array<Puzzle_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Tag_Order_By>>;
  where?: Maybe<Puzzle_Tag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPuzzle_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Puzzle_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Tag_Order_By>>;
  where?: Maybe<Puzzle_Tag_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPuzzle_Tag_By_PkArgs = {
  puzzle_id: Scalars['Int'];
  tag_id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootSolveArgs = {
  distinct_on?: Maybe<Array<Solve_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Solve_Order_By>>;
  where?: Maybe<Solve_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSolve_AggregateArgs = {
  distinct_on?: Maybe<Array<Solve_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Solve_Order_By>>;
  where?: Maybe<Solve_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSolve_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootTagsArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: Maybe<Array<Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_Order_By>>;
  where?: Maybe<Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "tags" */
export type Tags = {
  __typename?: 'tags';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  puzzle_tags: Array<Puzzle_Tag>;
  /** An aggregated array relationship */
  puzzle_tags_aggregate: Puzzle_Tag_Aggregate;
};


/** columns and relationships of "tags" */
export type TagsPuzzle_TagsArgs = {
  distinct_on?: Maybe<Array<Puzzle_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Tag_Order_By>>;
  where?: Maybe<Puzzle_Tag_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsPuzzle_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Puzzle_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Puzzle_Tag_Order_By>>;
  where?: Maybe<Puzzle_Tag_Bool_Exp>;
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'tags_aggregate_fields';
  avg?: Maybe<Tags_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
  stddev?: Maybe<Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Tags_Sum_Fields>;
  var_pop?: Maybe<Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Tags_Var_Samp_Fields>;
  variance?: Maybe<Tags_Variance_Fields>;
};


/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tags" */
export type Tags_Aggregate_Order_By = {
  avg?: Maybe<Tags_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tags_Max_Order_By>;
  min?: Maybe<Tags_Min_Order_By>;
  stddev?: Maybe<Tags_Stddev_Order_By>;
  stddev_pop?: Maybe<Tags_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tags_Stddev_Samp_Order_By>;
  sum?: Maybe<Tags_Sum_Order_By>;
  var_pop?: Maybe<Tags_Var_Pop_Order_By>;
  var_samp?: Maybe<Tags_Var_Samp_Order_By>;
  variance?: Maybe<Tags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tags" */
export type Tags_Arr_Rel_Insert_Input = {
  data: Array<Tags_Insert_Input>;
  on_conflict?: Maybe<Tags_On_Conflict>;
};

/** aggregate avg on columns */
export type Tags_Avg_Fields = {
  __typename?: 'tags_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tags" */
export type Tags_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Tags_Bool_Exp>>>;
  _not?: Maybe<Tags_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Tags_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  puzzle_tags?: Maybe<Puzzle_Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint */
  TagsNameKey = 'tags_name_key',
  /** unique or primary key constraint */
  TagsPkey = 'tags_pkey'
}

/** input type for incrementing integer column in table "tags" */
export type Tags_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  puzzle_tags?: Maybe<Puzzle_Tag_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'tags_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tags" */
export type Tags_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'tags_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tags" */
export type Tags_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  on_conflict?: Maybe<Tags_On_Conflict>;
};

/** on conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns: Array<Tags_Update_Column>;
  where?: Maybe<Tags_Bool_Exp>;
};

/** ordering options when selecting data from "tags" */
export type Tags_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  puzzle_tags_aggregate?: Maybe<Puzzle_Tag_Aggregate_Order_By>;
};

/** primary key columns input for table: "tags" */
export type Tags_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Tags_Stddev_Fields = {
  __typename?: 'tags_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tags" */
export type Tags_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tags_Stddev_Pop_Fields = {
  __typename?: 'tags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tags" */
export type Tags_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tags_Stddev_Samp_Fields = {
  __typename?: 'tags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tags" */
export type Tags_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tags_Sum_Fields = {
  __typename?: 'tags_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tags" */
export type Tags_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Tags_Var_Pop_Fields = {
  __typename?: 'tags_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tags" */
export type Tags_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tags_Var_Samp_Fields = {
  __typename?: 'tags_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tags" */
export type Tags_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tags_Variance_Fields = {
  __typename?: 'tags_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tags" */
export type Tags_Variance_Order_By = {
  id?: Maybe<Order_By>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  id: Scalars['Int'];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: Maybe<Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
  stddev?: Maybe<Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Users_Sum_Order_By>;
  var_pop?: Maybe<Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Users_Var_Samp_Order_By>;
  variance?: Maybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing integer column in table "users" */
export type Users_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

export type AllPuzzlesQueryVariables = {};


export type AllPuzzlesQuery = (
  { __typename?: 'query_root' }
  & { puzzle: Array<(
    { __typename?: 'puzzle' }
    & Pick<Puzzle, 'id' | 'hash' | 'title' | 'data'>
    & { puzzle_tags: Array<(
      { __typename?: 'puzzle_tag' }
      & { tag: (
        { __typename?: 'tags' }
        & Pick<Tags, 'name'>
      ) }
    )> }
  )> }
);

export type AllPuzzlesAndSolvesQueryVariables = {
  user: Scalars['String'];
};


export type AllPuzzlesAndSolvesQuery = (
  { __typename?: 'query_root' }
  & { puzzle: Array<(
    { __typename?: 'puzzle' }
    & Pick<Puzzle, 'id' | 'hash' | 'title' | 'author'>
    & { puzzle_tags: Array<(
      { __typename?: 'puzzle_tag' }
      & { tag: (
        { __typename?: 'tags' }
        & Pick<Tags, 'id' | 'name'>
      ) }
    )>, solves: Array<(
      { __typename?: 'solve' }
      & Pick<Solve, 'id' | 'solved'>
    )>, solves_aggregate: (
      { __typename?: 'solve_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'solve_aggregate_fields' }
        & Pick<Solve_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type PuzzleByIdQueryVariables = {
  id: Scalars['Int'];
};


export type PuzzleByIdQuery = (
  { __typename?: 'query_root' }
  & { puzzle_by_pk?: Maybe<(
    { __typename?: 'puzzle' }
    & Pick<Puzzle, 'id' | 'hash' | 'title' | 'data'>
  )> }
);

export type UpsertSolveMutationVariables = {
  solves: Array<Solve_Insert_Input>;
};


export type UpsertSolveMutation = (
  { __typename?: 'mutation_root' }
  & { insert_solve?: Maybe<(
    { __typename?: 'solve_mutation_response' }
    & { returning: Array<(
      { __typename?: 'solve' }
      & Pick<Solve, 'id' | 'solved' | 'solve_time'>
    )> }
  )> }
);

export type MyPuzzleSolveQueryVariables = {
  puzzleId: Scalars['Int'];
  user: Scalars['String'];
};


export type MyPuzzleSolveQuery = (
  { __typename?: 'query_root' }
  & { solve: Array<(
    { __typename?: 'solve' }
    & Pick<Solve, 'id' | 'fill' | 'solved' | 'time_in_seconds' | 'solve_time'>
  )> }
);

export type SolvesQueryVariables = {
  where?: Maybe<Solve_Bool_Exp>;
};


export type SolvesQuery = (
  { __typename?: 'query_root' }
  & { solve: Array<(
    { __typename?: 'solve' }
    & Pick<Solve, 'id' | 'solved' | 'solve_time'>
    & { puzzle: (
      { __typename?: 'puzzle' }
      & Pick<Puzzle, 'id' | 'title'>
    ) }
  )> }
);


export const AllPuzzlesDocument = gql`
    query allPuzzles {
  puzzle {
    id
    hash
    title
    data
    puzzle_tags {
      tag {
        name
      }
    }
  }
}
    `;

/**
 * __useAllPuzzlesQuery__
 *
 * To run a query within a React component, call `useAllPuzzlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPuzzlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPuzzlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPuzzlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllPuzzlesQuery, AllPuzzlesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllPuzzlesQuery, AllPuzzlesQueryVariables>(AllPuzzlesDocument, baseOptions);
      }
export function useAllPuzzlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPuzzlesQuery, AllPuzzlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllPuzzlesQuery, AllPuzzlesQueryVariables>(AllPuzzlesDocument, baseOptions);
        }
export type AllPuzzlesQueryHookResult = ReturnType<typeof useAllPuzzlesQuery>;
export type AllPuzzlesLazyQueryHookResult = ReturnType<typeof useAllPuzzlesLazyQuery>;
export type AllPuzzlesQueryResult = ApolloReactCommon.QueryResult<AllPuzzlesQuery, AllPuzzlesQueryVariables>;
export const AllPuzzlesAndSolvesDocument = gql`
    query allPuzzlesAndSolves($user: String!) {
  puzzle(limit: 200) {
    id
    hash
    title
    author
    puzzle_tags {
      tag {
        id
        name
      }
    }
    solves(where: {user: {_eq: $user}}) {
      id
      solved
    }
    solves_aggregate(where: {solved: {_eq: true}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useAllPuzzlesAndSolvesQuery__
 *
 * To run a query within a React component, call `useAllPuzzlesAndSolvesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPuzzlesAndSolvesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPuzzlesAndSolvesQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAllPuzzlesAndSolvesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllPuzzlesAndSolvesQuery, AllPuzzlesAndSolvesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllPuzzlesAndSolvesQuery, AllPuzzlesAndSolvesQueryVariables>(AllPuzzlesAndSolvesDocument, baseOptions);
      }
export function useAllPuzzlesAndSolvesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPuzzlesAndSolvesQuery, AllPuzzlesAndSolvesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllPuzzlesAndSolvesQuery, AllPuzzlesAndSolvesQueryVariables>(AllPuzzlesAndSolvesDocument, baseOptions);
        }
export type AllPuzzlesAndSolvesQueryHookResult = ReturnType<typeof useAllPuzzlesAndSolvesQuery>;
export type AllPuzzlesAndSolvesLazyQueryHookResult = ReturnType<typeof useAllPuzzlesAndSolvesLazyQuery>;
export type AllPuzzlesAndSolvesQueryResult = ApolloReactCommon.QueryResult<AllPuzzlesAndSolvesQuery, AllPuzzlesAndSolvesQueryVariables>;
export const PuzzleByIdDocument = gql`
    query puzzleById($id: Int!) {
  puzzle_by_pk(id: $id) {
    id
    hash
    title
    data
  }
}
    `;

/**
 * __usePuzzleByIdQuery__
 *
 * To run a query within a React component, call `usePuzzleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePuzzleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePuzzleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePuzzleByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PuzzleByIdQuery, PuzzleByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<PuzzleByIdQuery, PuzzleByIdQueryVariables>(PuzzleByIdDocument, baseOptions);
      }
export function usePuzzleByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PuzzleByIdQuery, PuzzleByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PuzzleByIdQuery, PuzzleByIdQueryVariables>(PuzzleByIdDocument, baseOptions);
        }
export type PuzzleByIdQueryHookResult = ReturnType<typeof usePuzzleByIdQuery>;
export type PuzzleByIdLazyQueryHookResult = ReturnType<typeof usePuzzleByIdLazyQuery>;
export type PuzzleByIdQueryResult = ApolloReactCommon.QueryResult<PuzzleByIdQuery, PuzzleByIdQueryVariables>;
export const UpsertSolveDocument = gql`
    mutation upsertSolve($solves: [solve_insert_input!]!) {
  insert_solve(objects: $solves, on_conflict: {constraint: solve_puzzle_id_user_key, update_columns: [fill, time_in_seconds]}) {
    returning {
      id
      solved
      solve_time
    }
  }
}
    `;
export type UpsertSolveMutationFn = ApolloReactCommon.MutationFunction<UpsertSolveMutation, UpsertSolveMutationVariables>;

/**
 * __useUpsertSolveMutation__
 *
 * To run a mutation, you first call `useUpsertSolveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSolveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSolveMutation, { data, loading, error }] = useUpsertSolveMutation({
 *   variables: {
 *      solves: // value for 'solves'
 *   },
 * });
 */
export function useUpsertSolveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertSolveMutation, UpsertSolveMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertSolveMutation, UpsertSolveMutationVariables>(UpsertSolveDocument, baseOptions);
      }
export type UpsertSolveMutationHookResult = ReturnType<typeof useUpsertSolveMutation>;
export type UpsertSolveMutationResult = ApolloReactCommon.MutationResult<UpsertSolveMutation>;
export type UpsertSolveMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertSolveMutation, UpsertSolveMutationVariables>;
export const MyPuzzleSolveDocument = gql`
    query myPuzzleSolve($puzzleId: Int!, $user: String!) {
  solve(where: {puzzle_id: {_eq: $puzzleId}, user: {_eq: $user}}) {
    id
    fill
    solved
    time_in_seconds
    solve_time
  }
}
    `;

/**
 * __useMyPuzzleSolveQuery__
 *
 * To run a query within a React component, call `useMyPuzzleSolveQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPuzzleSolveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPuzzleSolveQuery({
 *   variables: {
 *      puzzleId: // value for 'puzzleId'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useMyPuzzleSolveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyPuzzleSolveQuery, MyPuzzleSolveQueryVariables>) {
        return ApolloReactHooks.useQuery<MyPuzzleSolveQuery, MyPuzzleSolveQueryVariables>(MyPuzzleSolveDocument, baseOptions);
      }
export function useMyPuzzleSolveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyPuzzleSolveQuery, MyPuzzleSolveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyPuzzleSolveQuery, MyPuzzleSolveQueryVariables>(MyPuzzleSolveDocument, baseOptions);
        }
export type MyPuzzleSolveQueryHookResult = ReturnType<typeof useMyPuzzleSolveQuery>;
export type MyPuzzleSolveLazyQueryHookResult = ReturnType<typeof useMyPuzzleSolveLazyQuery>;
export type MyPuzzleSolveQueryResult = ApolloReactCommon.QueryResult<MyPuzzleSolveQuery, MyPuzzleSolveQueryVariables>;
export const SolvesDocument = gql`
    query solves($where: solve_bool_exp) {
  solve(where: $where) {
    id
    solved
    solve_time
    puzzle {
      id
      title
    }
  }
}
    `;

/**
 * __useSolvesQuery__
 *
 * To run a query within a React component, call `useSolvesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolvesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolvesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSolvesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SolvesQuery, SolvesQueryVariables>) {
        return ApolloReactHooks.useQuery<SolvesQuery, SolvesQueryVariables>(SolvesDocument, baseOptions);
      }
export function useSolvesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SolvesQuery, SolvesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SolvesQuery, SolvesQueryVariables>(SolvesDocument, baseOptions);
        }
export type SolvesQueryHookResult = ReturnType<typeof useSolvesQuery>;
export type SolvesLazyQueryHookResult = ReturnType<typeof useSolvesLazyQuery>;
export type SolvesQueryResult = ApolloReactCommon.QueryResult<SolvesQuery, SolvesQueryVariables>;