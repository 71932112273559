import React from "react"
import { Link } from "react-router-dom"
import { useAllPuzzlesAndSolvesQuery } from "app/src/generated/graphql"
import { Box, Tag } from "@chakra-ui/core"

import { useAuth0 } from "../../auth0"

const Puzzles = () => {
  const { userId } = useAuth0()
  const { data, loading } = useAllPuzzlesAndSolvesQuery({
    variables: { user: userId },
    fetchPolicy: "cache-and-network",
  })

  console.log(data)

  return (
    <Box p={2} px={4}>
      {data?.puzzle
        ? data.puzzle.map((puzzle) => (
            <Box key={puzzle.id} py={1}>
              <Box>
                <Link to={`/puzzle/${puzzle.id}`}>{puzzle.title}</Link>
                {puzzle.solves?.[0]
                  ? puzzle.solves[0].solved
                    ? "- Solved"
                    : "- In Progress"
                  : null}
              </Box>
              <Box>{puzzle.author}</Box>
              <Box>
                {puzzle.solves_aggregate.aggregate?.count} solves
                {puzzle?.puzzle_tags.map((t) => (
                  <Tag size="sm" key={t.tag.id}>
                    {t.tag.name}
                  </Tag>
                ))}
              </Box>
            </Box>
          ))
        : null}
    </Box>
  )
}

const Home = () => {
  const { isAuthenticated } = useAuth0()

  if (!isAuthenticated) return <div>Please log in!</div>
  return (
    <div>
      <Puzzles />
    </div>
  )
}

export default Home
