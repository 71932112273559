import PUZ from "./test"

type BasePuzzle = typeof PUZ

const inBounds = (puzzle: BasePuzzle, [r, c]) =>
  0 <= r && r < puzzle.height && 0 <= c && c < puzzle.width
export const isBlack = (puzzle: BasePuzzle, [r, c]) =>
  inBounds(puzzle, [r, c]) && (puzzle as any).shape[r][c] === 0
export const isFillable = (puzzle: BasePuzzle, [r, c]) =>
  inBounds(puzzle, [r, c]) && (puzzle as any).shape[r][c] === 1

export const isEmpty = (puzzle: BasePuzzle, fill, [r, c]) =>
  inBounds(puzzle, [r, c]) && !fill[r][c]

export const nextSquareAtOffset = (
  puzzle: BasePuzzle,
  fill,
  [r, c],
  [dr, dc],
  skipFilled = false
): [number, number] => {
  let [tr, tc] = [r + dr, c + dc]
  while (isBlack(puzzle, [tr, tc])) {
    tr += dr
    tc += dc
  }
  if (!inBounds(puzzle, [tr, tc])) return [r, c]
  return [tr, tc]
}

export const nextFillSquareAtOffset = (
  puzzle: BasePuzzle,
  fill,
  [r, c],
  [dr, dc],
  skipFilled = false
): [number, number] => {
  let [tr, tc] = [r + dr, c + dc]
  while (inBounds(puzzle, [tr, tc]) && !isEmpty(puzzle, fill, [tr, tc])) {
    tr += dr
    tc += dc
  }
  if (isBlack(puzzle, [tr, tc]) || !inBounds(puzzle, [tr, tc])) {
    if (isFillable(puzzle, [r + dr, c + dc])) {
      return [r + dr, c + dc]
    }
    return [r, c]
  }

  return [tr, tc]
}

export const preprocessPuzzle = (puzzle: BasePuzzle) => {
  const cluePosToClue = {}
  const clueHash = {}
  const numberToPos = {}
  puzzle.numbers.forEach(({ n, pos: [r, c] }) => {
    cluePosToClue[`${r},${c}`] = n
    numberToPos[n] = [r, c]
  })
  puzzle.clues.forEach(({ n, direction, text }) => {
    clueHash[`${n}${direction}`] = { n, direction, text }
  })
  const nextClue = [{}, {}]
  const previousClue = [{}, {}]
  for (let d = 0; d < 2; d++) {
    const directionClues = puzzle.clues.filter((c) => c.direction === "DA"[d])
    directionClues.forEach((c, i) => {
      nextClue[d][c.n] = directionClues[(i + 1) % directionClues.length].n
      previousClue[d][c.n] =
        directionClues[
          (i - 1 + directionClues.length) % directionClues.length
        ].n
    })
  }

  // For each position, compute start and end positions of clue.
  const posToStartPos = [{}, {}]
  const posToEndPos = [{}, {}]
  const posToClue = [{}, {}]
  for (let d = 0; d < 2; d++) {
    for (let r = 0; r < puzzle.height; r++) {
      for (let c = 0; c < puzzle.width; c++) {
        if (isBlack(puzzle, [r, c])) continue
        const [dr, dc] = d === 0 ? [1, 0] : [0, 1]

        let [sr, sc] = [r, c]
        while (true) {
          let [nr, nc] = [sr - dr, sc - dc]
          if (isBlack(puzzle, [nr, nc]) || !inBounds(puzzle, [nr, nc])) {
            break
          }
          ;[sr, sc] = [nr, nc]
        }

        posToStartPos[d][`${r},${c}`] = [sr, sc]
        posToClue[d][`${r},${c}`] = cluePosToClue[`${sr},${sc}`]
        ;[sr, sc] = [r, c]
        while (true) {
          let [nr, nc] = [sr + dr, sc + dc]
          if (isBlack(puzzle, [nr, nc]) || !inBounds(puzzle, [nr, nc])) {
            break
          }
          ;[sr, sc] = [nr, nc]
        }

        posToEndPos[d][`${r},${c}`] = [sr, sc]
      }
    }
  }

  for (let d = 0; d < 2; d++) {
    for (let r = 0; r < puzzle.height; r++) {
      for (let c = 0; c < puzzle.width; c++) {
        if (isBlack(puzzle, [r, c])) continue
      }
    }
  }

  return {
    ...puzzle,
    clueHash,
    cluePosToClue,
    posToStartPos,
    posToEndPos,
    posToClue,
    numberToPos,
    nextClue,
    previousClue,
  }
}
